import React from 'react';
import util from '../../utilities';

class UpButton extends React.Component {

    render() {

        return (
            <footer className='border-top border-white'>
                <div
                    onClick={() => util.scrollTo(1000, document.body, 0)}
                    className='container p4 sm-p6 md-p7 lg-px0 clearfix cursor-pointer'>
                    <div className='col-right'>
                        <i className='h2 icon icon-arrow-up inline-block' />
                    </div>
                    <div className='overflow-hidden pt1'>
                        Back to top
                    </div>
                </div>
            </footer>
        );
    }

}

module.exports = UpButton;
