import React from 'react';
import util from '../../../utilities';

class Filter extends React.Component {

    render() {

        let filterOptions = util.try(() => this.props.filter.options.map((option,i) => {
            const active = (this.props.filter.current === option.value)
                ? 'bg-yellow'
                : 'bg-gray-02';

            return (
                <li
                    key={i}
                    className='inline'
                    onClick={this.props.handleFilter.bind(this, this.props.name, option.value)}>
                    <button
                        className={`button border-0 h4 mt2 mr2 px3 col line-height-7 pt2px pb0 letter-spacing-1 ${active}`}
                        dangerouslySetInnerHTML={{__html: option.label}} />
                </li>
            );
        }));

        const filterActive = this.props.active === this.props.name
            ? ''
            : 'hide';

        const buttonActive = this.props.filter.current === null
            ? 'bg-yellow'
            : 'bg-gray-02';

        return (
            <div className={filterActive}>
                <ul className='list-reset clearfix'>
                    <li
                        key={-1}
                        tabIndex={0}
                        className='inline'
                        onClick={this.props.handleFilter.bind(this, this.props.name, null)}>
                        {!this.props.defaultFilter &&
                            <button className={`button border-0 h4 mt2 mr2 px3 col line-height-7 pt2px pb0 letter-spacing-1 ${buttonActive}`}>
                                All
                            </button>
                        }
                    </li>
                    {filterOptions}
                </ul>
            </div>
        );
    }
}

module.exports = Filter;
