/**
*   Grid item for listing
*/
import React from 'react';
import util from '../../../utilities';
import Image from '../../images/image.jsx';
import LoadedImage from '../../images/loaded-image.jsx';


class FeatureItem extends React.Component {

    render () {

        const item = this.props.item;
        const baseUrl = this.props.baseUrl;
        const title = item.title.rendered ? item.title.rendered : item.title;

        let standfirst = util.try(() => item.acf.standfirst);
        if (util.try(() => item.acf.secondary_standfirst)) {
            standfirst = standfirst + ' ' + item.acf.secondary_standfirst;
        }

        return (
            <div className='border-bottom border-light-grey-001 md-sticky col col-12 md-col-6 top-0'>
                <a className='block bg-hover-pastel-yellow clearfix pb6' href={`${baseUrl}/${item.slug}`}>
                    <h2
                        dangerouslySetInnerHTML={{__html: title}}
                        className='caslon sm-center px5 md-px7 sm-mt8 mt6 mb0' />
                    <div className='clearfix py6 sm-pt7'>
                        <div className='px5 sm-px9 md-px5 caslon clearfix'>
                            <p
                                dangerouslySetInnerHTML={{__html: standfirst}}
                                className='sm-column-count-2 line-height-2 sm-column-gap-1 m0' />

                        </div>
                    </div>
                    <div className='px5 sm-pl9 sm-pr9'>
                        <LoadedImage
                            width={100}
                            mdWidth={50}
                            image={util.try(() => item.acf.listing_image)} />
                    </div>
                </a>
            </div>
        );
    }
}

module.exports = FeatureItem;
