/*
 * Pagination
 */

import React from 'react';
// import PageSection from '../../layout/page-section.jsx';


/**
 * Class representing the pagination UI
 * @extends React.Component
 * @prop {number} current
 * @prop {number} total
 * @prop {function} navigate
 */
class Pagination extends React.Component {

    render() {

        let next;
        let previous;

        if(this.props.total === 1) {
            return (<span></span>);
        }

        // Build the pagination links
        const links = (current, total) => {

            let links = [];

            const link = (page, current) => {
                if (page === current) {
                    return (
                        <span className='black caps width-2 height-2 block bg-grey-002'>{page}</span>
                    );
                } else {
                    return (
                        <span
                            onClick={this.props.navigate.bind(null, page)}
                            className='cursor-pointer bg-hover-accent-green caps h7 block width-2 height-2 bg-grey-001'>
                            {page}
                        </span>
                    );
                }
            };

            let lastPage = 0;

            for (let page = 1; page <= total; page++) {

                if (page === 1 ||
                    Math.abs((current - page)) < 3 ||
                    page === total) {

                    if (Math.abs((page - lastPage)) > 1) {
                        links.push((
                            <div
                                key={page + '-space'}
                                className='left mr1px h7'>
                                <span className='black caps width-2 height-2 block bg-grey-002'>...</span>
                            </div>
                        ));
                    }

                    links.push((
                        <div
                            key={page}
                            className='left mr1px h7'>
                            {link(page, current)}
                        </div>
                    ));
                    lastPage = page;
                }
            }

            return links;
        };

        if (this.props.current > 1) {
            previous = (
                <div
                    onClick={this.props.navigate.bind(null, this.props.current - 1)}
                    className='left cursor-pointer width-2 height-2 bg-grey-001 bg-hover-accent-green mr1px center h7'>
                    <i className='icon-chevron-left align-middle h7' />
                </div>
            );
        } else {
            previous = (
                <div className='left width-2 height-2 bg-grey-001 bg-hover-accent-green mr1px center h7'>
                    <i className='icon-chevron-left align-middle h7' />
                </div>
            );
        }
        if (this.props.current < this.props.total) {
            next = (
                <div
                    onClick={this.props.navigate.bind(null, this.props.current + 1)}
                    className='left cursor-pointer width-2 height-2 bg-grey-001 bg-hover-accent-green mr1px center h7'>
                    <i className='icon-chevron-right align-middle h7' />
                </div>
            );
        } else {
            next = (
                <div className='left width-2 height-2 bg-grey-001 bg-hover-accent-green mr1px center h7'>
                    <i className='icon-chevron-right align-middle h7' />
                </div>
            );
        }

        const pages = (
            <div className='clearfix center'>
                {links(this.props.current, this.props.total)}
            </div>
        );

        return (
            <div className="border-top border-light-grey-001 mtn1px py5">
                <nav className='block clearfix bg-grey-005 mt1px container'>
                    <div className='overflow-hidden px4 sm-px5 py1 clearfix'>
                        {previous}
                        {next}
                        {pages}
                    </div>
                </nav>
            </div>
        );
    }

}

module.exports = Pagination;
