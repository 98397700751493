import React from 'react';

module.exports = (props) => {
    const hex = {
        black: '#0c0c0c',
        white: '#ffffff'
    };
    return (
        <svg
            x="0px"
            y="0px"
            width="66px"
            height="269.644px"
            viewBox="-4.61 -7.356 912 269.644"
            className="logo"
            fill={hex[props.fill]}>
            <path d="M268.427,109.594c0.339,4.435,0.339,8.185,0.339,13.302c0,84.583-64.805,135.407-134.724,135.407
            	C57.643,258.303,0,198.613,0,125.964C0,50.588,60.71-3.983,135.065-3.983c60.368,0,112.212,40.586,126.196,89.36h-72.309
            	c-9.894-17.735-28.31-32.743-55.596-32.743c-31.72,0-68.896,23.534-68.896,73.33c0,52.867,37.52,75.721,69.237,75.721
            	c32.062,0,53.55-16.027,60.372-40.59h-75.377v-51.501H268.427z M291.264,0.792h77.765c42.633,0,62.416,4.093,80.834,24.555
            	c11.256,11.598,15.007,26.946,15.007,41.611c0,19.441-8.868,39.225-29.333,50.82c40.586,14.328,41.61,48.092,41.61,58.668
            	c0,20.121-7.844,42.291-23.191,54.91c-23.191,19.442-39.905,21.827-82.54,21.827h-80.151V0.792z M352.996,96.633h22.854
            	c8.868,0,27.285,0,27.285-20.124c0-19.1-17.396-19.1-27.285-19.1h-22.854V96.633z M352.996,196.567h30.354
            	c8.188,0,32.062,0,32.062-24.222c0-21.821-21.829-21.821-32.062-21.821h-30.354V196.567z M601.3,76.851l0.678,0.682
            	c3.067-1.363,7.509-1.363,12.277-1.363c36.839,0,79.473,28.309,79.473,85.609c0,57.643-44.684,96.184-97.892,96.184
            	c-31.717,0-61.053-11.595-78.104-34.104c-16.028-18.077-21.481-43.999-21.481-63.442c0-25.58,10.57-48.089,23.188-67.533
            	l62.761-92.088h74.689L601.3,76.851L601.3,76.851z M593.793,201.343c19.098,0,35.473-13.983,35.473-34.104
            	c0-16.37-12.276-34.447-35.473-34.447c-20.463,0-34.105,15.687-34.105,33.423C559.686,189.743,578.1,201.343,593.793,201.343
            	L593.793,201.343z M739.433,0.792h139.493v54.23h-90.041l-5.453,34.447c6.817-3.751,14.32-7.505,34.105-7.505
            	c59.687,0,84.241,49.8,84.241,84.589c0,38.54-28.989,91.749-96.866,91.749c-54.229,0-88.675-32.746-96.184-72.646h64.81
            	c5.795,7.844,12.277,11.931,17.736,13.639c5.452,2.049,9.887,2.049,11.936,2.049c18.421,0,34.106-15.004,34.106-33.423
            	c0-16.371-11.938-33.088-34.448-33.088c-12.619,0-25.579,4.774-33.087,21.829L717.599,143.7L739.433,0.792z" />
        </svg>
    );
};
