/*
 * Listing container
 */

import React from 'react';
import ListingContainer from './container.jsx';
import util from '../../../utilities';


/**
 * Class representing a listing container
 * @extends React.component
 * @prop {object} filters
 * @prop {array} items
 * @prop {number} pages
 * @prop {boolean} morePages
 */
class InfiniteListingContainer extends ListingContainer {

    /**
     * Setup
     */
    constructor(props) {
        super(props);

        this.handlePages = this.handlePages.bind(this);
        this.loadMorePosts = this.loadMorePosts.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.state = {
            filters: props.filters || {},
            items: props.items || [],
            pages: props.pages || 1,
            morePages: props.morePages || false,
            loading: false
        };
    }

    /**
     * Setup
     */
    componentDidMount() {
        this.setState({
            pushStateSupport: !!(window.history && history.pushState)
        });
    }

    /**
     * Check distance to bottom of page and trigger load if required
     */
    loadMorePosts() {
        if (this.state.morePages) {
            this.setState({
                loading: true
            }, this.handlePages(this.state.pages + 1));
        }
    }

    /**
     * Add another page
     * @param {number} pages
     * @param {function} callback
     */
    handlePages(pages, callback) {
        this.setState({
            pages: pages
        }, () => {
            if (this.state.pushStateSupport) {
                util.updateHistoryState('pages', pages);
            }
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    /**
     * Handle load
     */
    handleLoad(data) {
        this.setState({
            morePages: data.nextPage && typeof data.nextPage === 'number',
            loading: false
        });
    }

}

module.exports = InfiniteListingContainer;
