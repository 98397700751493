/*
* Header
*/

import React from 'react';
import Logo from '../ui/logo.jsx';
import util from '../../utilities';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            didScroll: false,
            previousScrollPosition: 0,
            scrollingUp: false,
            scrollingThreshold: 10,
            slideNavBar: false,
            showNavBar: false
        };
        this.toggle = this.toggle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', (() => {
            this.setState({
                didScroll: true
            });
        }));

        setInterval(() => {
            if (this.state.didScroll) {
                this.handleScroll();
                this.setState({
                    didScroll: false
                });
            }
        }, 150);

        this.navBar = this.refs.navBar;
    }

    handleScroll() {

        if (window.pageYOffset > window.innerHeight) {
            if (window.pageYOffset > this.state.previousScrollPosition) {
                this.setState({
                    slideNavBar: false,
                    scrollingUp: false,
                    scrollUpPosition: 0
                });
            } else if (window.pageYOffset < this.state.scrollUpPosition - this.state.scrollingThreshold) {
                this.setState({
                    slideNavBar: true
                });
            } else {
                if (!this.state.scrollingUp) {
                    this.setState({
                        scrollingUp: true,
                        scrollUpPosition: window.pageYOffset
                    });
                }
            }
        } else {
            this.setState({
                slideNavBar: false
            });
        }

        if (window.pageYOffset < this.state.navBar) {
            this.setState({
                showNavBar: false
            });
        } else {
            this.setState({
                showNavBar: true
            });
        }

        this.setState({
            previousScrollPosition: window.pageYOffset
        });

    }

    toggle() {
        this.setState({
            active: !this.state.active
        }, () => {
            document.body.classList.toggle('overflow-hidden', this.state.active);
        });



    }

    render() {

        const color = this.props.absolute
            ? 'white'
            : 'black';

        const burgerColor = this.props.absolute && !this.state.active
            ? 'white'
            : 'black';

        const active = this.state.active
            ? 'js-is-active'
            : '';

        const border = this.props.absolute
            ? ''
            : 'border-bottom sm-border-0 border-white';

        const menuItems = util.try(() => this.props.menuItems.map((item, i) => (
            <li
                key={i}
                className='ml6 inline-block'>
                <a
                    href={item.url}
                    className='h3 capitalize link-reset letter-spacing-003'>
                    {item.title}
                </a>
            </li>
        )));

        const mobileMenuItems = util.try(() => this.props.menuItems.map((item, i) => (
            <li
                key={i}
                className={`${active} overflow-hidden menu-item`}>
                <a
                    href={item.url}
                    className='block link-reset pl4 sm-pl6 border-bottom border-white'>
                    <div className="py4 h3 capitalize letter-spacing-003">
                        {item.title}
                    </div>
                </a>
            </li>
        )));

        return (

            <header>
                <div className='bg-gray-01 fixed top-0 right-0 left-0 z2 py9 inactive-slide' />
                <div className={`fixed top-0 right-0 left-0 border-bottom border-white bg-gray-01 z1 transition-transform ${this.state.slideNavBar ? 'active-slide' : 'inactive-slide'} ${this.state.showNavBar ? '' : 'hide'}`}>
                    <div className={'container ' + border}>
                        {/* Desktop Nav */}
                        <nav className='right mt2'>
                            <ul className={`p6 md-pr7 lg-pr0 sm-show list-reset m0 ${color}`}>
                                {menuItems}
                            </ul>
                        </nav>
                        <div
                            onClick={this.toggle}
                            className={`py6 pl6 pr4 z2 cursor-pointer right burger-icon sm-hide ${active}`}>
                            <span className={`bg-${burgerColor} transition line`} />
                            <span className={`bg-${burgerColor} transition line`} />
                            <span className={`bg-${burgerColor} transition line`} />
                        </div>
                        <a
                            href='/'
                            className='py6 pl4 sm-pl6 md-pl7 lg-pl0 inline-block overflow-hidden'>
                            <Logo fill={color} />
                        </a>
                        {/* Mobile Nav */}
                        <nav className='sm-hide'>
                            {this.state.active && (
                                <div className='bg-gray-01 z1 fixed top-0 bottom-0 left-0 right-0'>
                                    <a
                                        href='/'
                                        className='py6 pl4 sm-pl6 md-pl7 inline-block overflow-hidden'>
                                        <Logo fill={'black'} />
                                    </a>
                                    <div className='relative'>
                                        <div className='line-height-5' />
                                        <ul className='border-top border-white list-reset m0'>
                                            {mobileMenuItems}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </nav>
                    </div>
                </div>
                <div
                    ref='nav'
                    className={`${this.state.active ? 'fixed top-0 right-0 left-0 z3' : ''}`}>
                    <div className={'container ' + border}>
                        {/* Desktop Nav */}
                        <nav className='right mt2 sm-show'>
                            <ul className={`p6 md-pr7 lg-pr0 list-reset m0 ${color}`}>
                                {menuItems}
                            </ul>
                        </nav>
                        <div
                            onClick={this.toggle}
                            className={`py6 pl6 pr4 z2 cursor-pointer right burger-icon sm-hide ${active}`}>
                            <span className={`bg-${burgerColor} transition line`} />
                            <span className={`bg-${burgerColor} transition line`} />
                            <span className={`bg-${burgerColor} transition line`} />
                        </div>
                        <a
                            href='/'
                            className='py6 pl4 sm-pl6 md-pl7 lg-pl0 inline-block overflow-hidden'>
                            <Logo fill={color} />
                        </a>
                        {/* Mobile Nav */}
                        <nav className='sm-hide'>
                            {this.state.active && (
                                <div className='bg-gray-01 z1 fixed top-0 bottom-0 left-0 right-0'>
                                    <a
                                        href='/'
                                        className='py6 pl4 sm-pl6 md-pl7 inline-block overflow-hidden'>
                                        <Logo fill={'black'} />
                                    </a>
                                    <div className='relative'>
                                        <div className='line-height-5' />
                                        <ul className='border-top border-white list-reset m0'>
                                            {mobileMenuItems}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}


module.exports = Header;
