/**
*   Grid item for listing
*/
import React from 'react';
import util from '../../../utilities';
import Image from '../../images/image.jsx';
import LoadedImage from '../../images/loaded-image.jsx';

class Item extends React.Component {

    render () {

        const item = this.props.item;
        const baseUrl = this.props.baseUrl;
        const title = util.try(() => item.title.rendered ? item.title.rendered : item.title);

        let url;
        if (util.try(() => this.props.baseUrl)) {
            url = this.props.baseUrl + '/' + item.slug;
        } else {
            url = util.joinPath(item.type, item.slug);
        }

        return (
            <div className={`col col-12 sm-col-${this.props.width}`}>
                <a className='block link-reset height-100' href={url}>
                    <div className='p4 height-100 flex flex-column justify-between'>
                        {util.try(() => this.props.typeTitle) &&
                            <div className='px5 pt2 pt2'>
                                <span
                                    className='h7 caps'
                                    dangerouslySetInnerHTML={{__html:this.props.typeTitle}} />
                            </div>
                        }
                        <div className=''>
                            <LoadedImage
                                width={100}
                                smWidth={35}
                                mdWidth={25}
                                image={util.try(() => item.acf.listing_image) || util.try(() => item.acf.lead_image)} />
                        </div>
                        <div className='mt4 line-height-4'>
                            <h3
                                dangerouslySetInnerHTML={{__html: title}}
                                className='m0 medium letter-spacing-0' />
                            {item.type && item.type.length > 0 &&
                                <h3 className='m0 light'>
                                    {item.type && item.type.length > 0 ?
                                        util.try(() => item.type.map((type, i) => (
                                            <React.Fragment key={i}>
                                                {i > 0 &&
                                                    <span>, </span>
                                                }
                                                <span>{type.name}</span>
                                            </React.Fragment>
                                        )))
                                        :
                                        'nbsp;'
                                    }
                                </h3>
                            }
                        </div>
                    </div>
                </a>
                {this.props.clearfix &&
                    <div className='clearfix' />
                }
            </div>
        );
    }
}

export default Item;
