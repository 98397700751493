/*
 *  Project Detail Image Pair module
 */

import React from 'react';
import util from '../../utilities';
import Image from '../images/image.jsx';
import Lightbox from './lightbox.jsx';

/**
 * Class representing an Image Pair
 * @extends React.Component
 * @prop {object} item
 */
class ImagePair extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lightboxImage: null
        };
        this.setLightboxImage = this.setLightboxImage.bind(this);
    }

    setLightboxImage(image) {
        this.setState({
            lightboxImage: image
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className={`col-12 sm-col-6 p4 sm-p6 md-p7 border-white flex items-center border-top ${this.props.item.image_2 ? 'sm-border-right' : ''}`}>
                    <div
                        onClick={() => this.setLightboxImage(this.props.item.image_1)}
                        className='col-12 sm-show cursor-zoom'>
                        <Image smWidth={50} image={this.props.item.image_1} />
                    </div>
                    <div className='col-12 sm-hide'>
                        <Image smWidth={50} image={this.props.item.image_1} />
                    </div>
                </div>
                <div className={`col-12 sm-col-6 ${this.props.item.image_2 ? 'p4 sm-p6 md-p7 border-top' : ''} border-white flex items-center sm-border-top`}>
                    {util.try(() => this.props.item.image_2) &&
                        <React.Fragment>
                            <div
                                onClick={() => this.setLightboxImage(this.props.item.image_2)}
                                className='col-12 sm-show cursor-zoom'>
                                <Image smWidth={50} image={this.props.item.image_2} />
                            </div>
                            <div className='col-12 sm-hide'>
                                <Image smWidth={50} image={this.props.item.image_2} />
                            </div>
                        </React.Fragment>
                    }
                </div>
                {this.state.lightboxImage &&
                    <Lightbox
                        close={this.setLightboxImage}
                        image={this.state.lightboxImage} />
                }
            </React.Fragment>
        );
    }

}

module.exports = ImagePair;
