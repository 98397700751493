/*
 *  Article / Current. News, blog, press etc.
 */

import React from 'react';
import AjaxListing from '../base/infinite-ajax-listing.jsx';
import ListingGrid from '../base/listing-grid.jsx';
import util from '../../../utilities';

// Grid listing items
import FeatureItem from '../items/feature-item.jsx';
import Item from '../items/item.jsx';
import ImageItem from '../items/image-item.jsx';
import TextItem from '../items/text-item.jsx';



/**
 * Class representing the Article
 * @extends AjaxListing
 */
class ProjectListing extends AjaxListing {

    render() {

        let items = [];
        const pageSize = this.props.pageSize || 2;

        const buildBorders = (index) => {
            let borders = [];
            for (let k = 1; k < pageSize; k++) {

                if (this.state.items[index + k]) {
                    borders.push(
                        <div
                            key={index + k + '-border'}
                            style={{
                                backgroundColor: 'white',
                                width: '1px',
                                height: '100%',
                                left: 100 / pageSize * k + '%',
                                position: 'absolute'}} />
                    );
                }
            }
            return borders;
        };

        const buildRow = (RowType, keyType, index) => {
            for (let j = 0; j < pageSize; j++) {
                if (this.state.items[index+j]) {
                    const id = this.state.items[index+j].id || this.state.items[index+j].ID;
                    items.push(
                        <RowType
                            typeTitle=''
                            key={id + keyType}
                            width={12 / pageSize}
                            baseUrl={'/work'}
                            item={this.state.items[index+j]} />
                    );
                }
            }

            items.push(
                <div
                    key={index + keyType + '-clearfix'}
                    className={`clearfix relative pointer-events-none lg-px7 ${keyType === 'text' ? 'sm-border-bottom border-white lg-border-0' : ''}`}>
                    <div className={`absolute top-0 right-0 bottom-0 left-0 ${keyType === 'image' ? 'pt4 sm-pt6 md-pt7' : 'pb4 sm-pb6 md-pb7'}`}>
                        <div className='relative col-12 height-100'>
                            {buildBorders(index)}
                        </div>
                    </div>
                    {keyType === 'text' &&
                        <div className='absolute bottom-0 left-0 right-0 lg-px7 lg-show'>
                            <div className='border-bottom border-white' />
                        </div>
                    }
                </div>
            );
        };

        if (this.state.items && this.state.items.length > 0) {

            for (let i = 0; i < this.state.items.length; i=i+pageSize) {

                buildRow(ImageItem, 'image', i);
                buildRow(TextItem, 'text', i);

            }
        }

        const mobileItems = util.try(() => this.state.items.map((item, i) => {
            return (
                <Item
                    typeTitle=''
                    key={i + 'mobile'}
                    width={12}
                    baseUrl={'/work'}
                    item={this.state.items[i]} />
            );
        }));

        return (
            <div className='mln1px lg-mxn7 clearfix'>
                <React.Fragment>
                    <div className={`sm-show ${this.props.divider ? 'border-top' : ''} md-border-0 border-white`}>
                        {items}
                    </div>
                    <div className='sm-hide'>
                        {mobileItems}
                    </div>
                </React.Fragment>
                {this.state.items.length <= 0 && !this.state.loading && (
                    <div className='container'>
                        <h3 className='light px4 sm-px6 md-px7 pt4 sm-pt6 md-pt7 lg-pl0 m0'>
                            No results found
                        </h3>
                    </div>
                )}
            </div>
        );
    }
}

module.exports = ProjectListing;
