/*
 * Utilities
 */


/**
  * Run a method safely
  * Useful for accessing a nested property of an object safely
  * e.g. util.try(() => object.property.property);
  * @param {function} method
  * @returns {any} result
  */
const tryMethod = (method) => {
    try {
        return method();
    } catch (e) {
        return null;
    }
};

module.exports = {
    /**
     * Check if a value is a valid array with one or more elements
     * @param {any} array
     * @returns {boolean} result
     */
    isArray: (array) => {
        if (array &&
            Array.isArray(array) &&
            array.length > 0) {
            return true;
        } else {
            return false;
        }
    },
    try: tryMethod,
    /**
     * Smooth scroll to an element
     * @param {number} duration
     * @param {object} targetElement
     */
    scrollTo(duration, targetElement) {

        const start = window.pageYOffset;
        const change = targetElement.getBoundingClientRect().top;
        let currentTime = 0;
        const increment = 20;

        duration = (typeof(duration) === 'undefined') ? 500 : duration;

        const easeIn = (t, b, c, d) => {
            const tc = (t /= d) * t * t;
            return b + c * (tc);
        }

        const animateScroll = () => {
            currentTime += increment;
            const val = easeIn(currentTime, start, change, duration);
            window.scrollTo(0, val);

            if (currentTime < duration) {
                window.requestAnimationFrame(animateScroll);
            }
        }
        animateScroll();

    },
    /**
     * Cookie utilities
     * src: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
     */
    cookies: {
        getItem: (sKey) => {
            if (!sKey) {
                return null;
            }
            return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
        },
        setItem: (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {
            if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
                return false;
            }
            let sExpires = '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
            document.cookie = encodeURIComponent(sKey) + '=' + encodeURIComponent(sValue) + sExpires + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '') + (bSecure ? '; secure' : '');
            return true;
        },
        removeItem: (sKey, sPath, sDomain) => {
            if (!this.hasItem(sKey)) {
                return false;
            }
            document.cookie = encodeURIComponent(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '');
            return true;
        },
        hasItem: (sKey) => {
            if (!sKey) {
                return false;
            }
            return (new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=')).test(document.cookie);
        },
        keys: () => {
            let aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:\=[^;]*)?;\s*/);
            for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
            return aKeys;
        }
    },
    /**
     * Update browser history state
     * @param {string} key
     * @returns {any} value
     */
    updateHistoryState: (key, value) => {
        let url = window.location.href;
        let re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi');
        let hash;
        if (re.test(url)) {
            if (typeof value !== 'undefined' && value !== null) {
                url = url.replace(re, '$1' + key + '=' + value + '$2$3');
            } else {
                hash = url.split('#');
                url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
                if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                    url += '#' + hash[1];
                }
            }
        } else {
            if (typeof value === 'undefined' && value === null) {
                return;
            }
            let separator = url.indexOf('?') !== -1 ? '&' : '?';
            hash = url.split('#');
            url = hash[0] + separator;
            if (Array.isArray(value)) {
                value.forEach((val, i) => {
                    if (i > 0) {
                        url += '&';
                    }
                    url += key + '=' + val;
                });
            } else if (typeof value === 'string' || typeof value === 'number') {
                url += key + '=' + value;
            }
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                url += '#' + hash[1];
            }
        }
        history.replaceState({}, '', url);
    }
};
