/*
 *  Home Image Pair module
 */

import React from 'react';
import util from '../../utilities';
import Image from '../images/image.jsx';
import ListingVideo from '../video/listing-video.jsx';

/**
 * Class representing an Image Pair
 * @extends React.Component
 * @prop {array} projects
 */
class ImagePair extends React.Component {

    render() {

        const projects = this.props.projects;

        return (
            <div className='clearfix'>

                {projects.map((item, i) => {

                    let image = '';
                    if (util.try(() => item.acf.homepage_listing_image)) {
                        image = util.try(() => item.acf.homepage_listing_image) || util.try(() => item.acf.homepage_listing_image.url);
                    } else if (util.try(() => item.acf.listing_image)) {
                        image = util.try(() => item.acf.listing_image) || util.try(() => item.acf.listing_image.url);
                    }

                    let content;
                    if (util.try(() => item.acf.homepage_listing_video_embed)) {
                        content = (
                            <ListingVideo
                                video={item.acf.homepage_listing_video_embed}
                                previewImage={util.try(() => item.acf.homepage_listing_preview_image.sizes['custom-medium'])}
                                aspectWidth={util.try(() => item.acf.homepage_listing_video_aspect_ratio_width) || 16}
                                aspectHeight={util.try(() => item.acf.homepage_listing_video_aspect_ratio_height) || 9} />
                        );
                    } else {
                        content = (
                            <Image image={util.try(() => item.acf.homepage_listing_image) || util.try(() => item.acf.listing_image)} />
                        );
                    }

                    return (
                        <a
                            key={i}
                            className='link-reset hover-underline'
                            href={'/work/' + item.slug}>
                            <div
                                className={`col col-12 sm-col-6 relative border-white ${i % 2 === 0 ? 'sm-border-right sm-mrn1px' : 'sm-border-left'}`}>
                                <div
                                    className='items-center col-12 height-100 relative'>
                                    {content}
                                </div>
                                <div className={`col-12 px4 sm-px6 md-px7 py6 sm-py7 half-container ${i % 2 === 0 ? 'md-col-right lg-pl0' : 'lg-pr0'}`}>
                                    <h3 className='mt0 mb1 medium inline-block relative'>
                                        <span dangerouslySetInnerHTML={{__html: item.title}} />
                                    </h3>
                                    <h3 className={`m0 light ${util.try(() => item.terms.type && item.terms.type.length > 0) ? '' : 'sm-show'}`}>
                                        {util.try(() => item.terms.type) ?
                                            util.try(() => item.terms.type.map((type, i) => (
                                                <React.Fragment key={i}>
                                                    {i > 0 &&
                                                        <span>, </span>
                                                    }
                                                    <span>{type.name}</span>
                                                </React.Fragment>
                                            )))
                                            :
                                            'nbsp;'
                                        }
                                    </h3>
                                </div>
                            </div>
                        </a>
                    );
                })}

            </div>
        );
    }
}

module.exports = ImagePair;
