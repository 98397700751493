/*
 * Columns
 */

import React from 'react';
import util from '../../utilities';

/**
 * Columns
 * @param {array} items
 * @param {number} count
 * @param {booean} responsive
 */
module.exports = (props) => {

    if (!util.isArray(props.items)) {
        return (<span />);
    }

    const count = props.count || 2;

    let cols = [];
    let i = 0;
    while (i < count) {
        cols.push([]);
        i++;
    }

    let colIndex = 0;
    props.items.forEach((item, i) => {
        cols[colIndex].push(
            <span key={i}>
                {props.items[i]}
            </span>
        );
        colIndex = (colIndex >= (count - 1)) ? 0 : colIndex + 1;
    });

    cols = cols.map((col, i) => {
        let classString = props.responsive ? 'col-12 sm-col-' + Math.floor(12 / count) : 'col-' + Math.floor(12 / count);
        if (util.try(() => props.shiftUpCol) && (props.shiftUpCol === i)) {
            classString = classString + ' md-mtn9';
        }
        return (
            <div
                key={i}
                className={'col ' + classString}>
                {col}
            </div>
        );
    });

    return (
        <span>
            {cols}
        </span>
    );

};
