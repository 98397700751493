/*
 *  Images
 */

import React from 'react';
const utilities = require('../../utilities');


/**
 * Fallback image component
 * @prop {string} className - additional CSS classes
 */
class Default extends React.Component {
    render() {
        return (
            <img
                className={`${this.props.className} col-12`}
                src='/images/img-loadplaceholder.jpg'
                alt='no image' />
        );
    }
};

/**
 * Class representing an image
 * With loader and support for responsive images
 * @prop {object} | {string} image - a WordPress image object or src url
 * @prop {string} className - additional CSS classes
 * @prop {string} alt - alt text for the image
 * @prop {number} width - percentage base width
 * @prop {number} smWidth - percentage sm breakpoint width
 * @prop {number} mdWidth - percentage md breakpoint width
 * @prop {number} lgWidth - percentage lg breakpoint width
 * @prop {string} aspect - use one of the cropped aspect ratios from WordPress eg. '2-3'
 */
class Image extends React.Component {

    constructor() {
        super();
        this.state = {
            loaded: false
        };
    }

    // update state when the image is loaded
    componentDidMount() {
        if (this.refs.image) {
            let imageNode = this.refs.image;
            imageNode.src = imageNode.currentSrc || ((typeof this.props.image === 'string') ? this.props.image : this.props.image.url);
            if (imageNode.complete) {
                this.handleOnLoad.call(this);
            } else {
                imageNode.onload = this.handleOnLoad.bind(this);
            }
        }
    }

    // fade in
    componentDidUpdate(prevProps, prevState) {
        if (this.state.loaded && !prevState.loaded) {
            window.requestAnimationFrame(() => {
                if (this.refs.image) {
                    this.refs.image.style.opacity = 1;
                }
            });
        }
    }

    // remove the loaded listener if the component will unmount
    componentWillUnmount() {
        if (this.refs.image && this.state.loaded === false) {
            let imageNode = this.refs.image;
            imageNode.src = imageNode.currentSrc || ((typeof this.props.image === 'string') ? this.props.image : this.props.image.url);
            imageNode.onload = undefined;
        }
    }

    // handle load
    handleOnLoad() {
        if (this.props.hasLoaded) {
            this.props.hasLoaded();
        }

        this.setState({
            loaded: true
        });
    }

    // render image
    render() {
        const classes = this.props.className || '';

        if (this.props.image && typeof this.props.image === 'string') {
            // Build img with simple src url
            return (
                <img
                    ref='image'
                    className={`col-12 block ${classes}`}
                    style={{...this.props.style, opacity: 0.01}}
                    src={this.props.image} />
            );

        } else if (this.props.image && this.props.image.mime_type) {
            // Don't build a srcset if the image is an svg
            if (this.props.image.mime_type.match('svg')) {

                return (
                    <img
                        className={this.props.className}
                        src={this.props.image.url}
                        alt={this.props.image.caption} />
                );

            } else if (typeof this.props.image.sizes === 'object') {

                let sm, md, lg, exlg;
                if (this.props.image.sizes[`custom-${this.props.aspect}-small`]) {
                    sm = this.props.image.sizes[`custom-${this.props.aspect}-small`];
                    md = this.props.image.sizes[`custom-${this.props.aspect}-medium`] || sm;
                    lg = this.props.image.sizes[`custom-${this.props.aspect}-large`] || md;
                    exlg = this.props.image.sizes[`custom-${this.props.aspect}-extra-large`] || lg;
                } else {
                    sm = this.props.image.sizes['custom-small'] || this.props.image.url;
                    md = this.props.image.sizes['custom-medium'] || sm;
                    lg = this.props.image.sizes['custom-large'] || md;
                    exlg = this.props.image.sizes['custom-extra-large'] || lg;
                }

                let sizes = '';
                sizes += (this.props.lgWidth) ? `(min-width: 1280px) ${this.props.lgWidth}vw, ` : '';
                sizes += (this.props.mdWidth) ? `(min-width: 1020px) ${this.props.mdWidth}vw, ` : '';
                sizes += (this.props.smWidth) ? `(min-width: 760px) ${this.props.smWidth}vw, ` : '';
                sizes += (this.props.width) ? `${this.props.width}vw` : '100vw';

                let srcSet = '';
                srcSet += sm + ' 320w, ';
                srcSet += md + ' 720w, ';
                srcSet += lg + ' 1440w, ';
                srcSet += exlg + ' 2880w';

                const height = this.props.aspect ? '' : (this.props.image.height / this.props.image.width) * 100 + '%';

                return (
                    <div className='relative bg-pastel-blue' style={{paddingTop: height}}>
                        <img
                            ref='image'
                            className={`col-12 block transition-opacity top-0 bottom-0 right-0 left-0 absolute ${classes}`}
                            style={{...this.props.style, opacity: 0.01, height: ''}}
                            sizes={sizes}
                            srcSet={srcSet}
                            alt={this.props.image.caption} />
                    </div>
                );

            } else {
                return <Default {...this.props} />
            }

        } else {
            return <Default {...this.props} />
        }
    }

};

module.exports = Image;
