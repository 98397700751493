
import React from 'react';
import Columns from '../../layout/columns.jsx';

class ItemsBlock extends React.Component {

    render() {

        return (
            <div className='border-top border-light-grey-001 mtn1px'>
                <div className='clearfix container '>
                    {this.props.col1Items}
                    <div className='col col-12 md-col-6 clearfix'>
                        <div className='sm-hide'>
                            {this.props.items}
                        </div>
                        <div className='sm-show'>
                            <Columns
                                items={this.props.items}
                                count={2} />
                        </div>
                        {this.props.loading &&
                            <div className='col col-12 md-col-6'>
                                <h4 className='px5 caslon loading-elipsis'>
                                    Loading more
                                </h4>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class ListingGrid extends React.Component {

    render() {

        const blockSize = this.props.blockSize ? this.props.blockSize : 7;
        let itemCount = 0;
        let col1 = [];
        let items = [];
        let blocks = [];
        let first = true;

        if(Array.isArray(this.props.children)) {
            this.props.children.forEach((item, i) => {

                if(itemCount === 0 || itemCount === blockSize) {

                    if(itemCount === blockSize) {

                        blocks.push(
                            <ItemsBlock
                                key={i}
                                items={items}
                                col1Items={col1}
                            />
                        );

                        // reset our arrays
                        items = [];
                        col1 = [];
                        itemCount = 0;
                    }

                    col1.push(item);
                } else {
                    items.push((
                        <div key={i}>
                            {item}
                        </div>
                    ));
                }

                //
                // Capture final block
                if(i === this.props.children.length-1 && col1.length > 0 || i === this.props.children.length-1 && items.length > 0) {

                    blocks.push(
                        <ItemsBlock
                            key={`f${i}`}
                            items={items}
                            col1Items={col1}
                            loading={this.props.loading}
                        />
                    );
                }

                itemCount++;
            });
        }

        return (
            <div>
                {blocks}
            </div>
        );
    }
}

module.exports = ListingGrid;
