import React from 'react';

const TextSection = (props) => (
    <section className='clearfix line-height-4 pt6 sm-pt8 px4 sm-px0 pb8'>
        <h3
            className='m0 col col-12 sm-col-4 light sm-px6 md-px7 lg-pl0 pb6'
            dangerouslySetInnerHTML={{__html: props.title}} />
        <div
            className='col col-12 sm-col-8 rte h3 sm-px6 md-px7 lg-pr0 limit-line-length-1'
            dangerouslySetInnerHTML={{__html: props.content}} />
    </section>
);

export default TextSection;
