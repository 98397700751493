/*
 *
 */

import React from 'react';
import util from '../utilities';
import Header from '../partials/global/header.jsx';
import Image from '../partials/images/image.jsx';
import TextSection from '../partials/shared/text-section.jsx';

const Page = (props) => {

    let blocks = [];

    if (props.page.acf.overview) {
        blocks.push(
            <div
                key={'overview'}
                className='border-top border-white'>
                <TextSection
                    title='Profile'
                    content={props.page.acf.overview} />
            </div>
        );
    }
    if (props.page.acf.team && props.page.acf.team.length > 0) {
        blocks.push(
            <section
                key={'team'}
                className='clearfix line-height-4 px2 sm-px0 pb8 lg-mxn7'>
                <div className='px2 sm-px6 md-px7 mb6 sm-mb8 '>
                    <div className='border-top border-white' />
                </div>
                <h3 className='m0 col col-12 sm-col-4 light px2 sm-px6 md-px7 pb6'>Our Team</h3>
                <div
                    className='col col-12 sm-col-8 sm-px3 md-px4'>
                    {props.page.acf.team.map((member, i) => (
                        <div
                            key={i}
                            className={`col ${i === 0 ? 'col-12' : 'col-6'} px2 sm-px3 md-px4 mb5`}>
                            <Image
                                width={i === 0 ? 100 : 50}
                                smWidth={i === 0 ? 60 : 30}
                                mdWidth={i === 0 ? 50 : 30}
                                image={member.image} />
                            <div className={i === 0 ? 'h3' : 'h4 sm-h3'}>
                                <p
                                    className='mb0'
                                    dangerouslySetInnerHTML={{__html: member.name}} />
                                <p
                                    className='mt0'
                                    dangerouslySetInnerHTML={{__html: member.title}} />
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        );
    }
    if (props.page.acf.studios && props.page.acf.studios.length > 0) {
        blocks.push(
            <section
                key={'studios'}
                className='border-top border-white md-border-0 clearfix line-height-4 py4 sm-py6 md-pb7 md-pt0'>
                {props.page.acf.studios.map((studio, i) => (
                    <div
                        key={i}
                        className={`col col-12 md-col-6 h3 mb6 px4 sm-px6 md-px7 ${i % 2 !== 0 ? 'md-border-left border-white md-mln1px lg-pr0' : 'lg-pl0'}`}>
                        <Image
                            mdWidth={45}
                            image={studio.image} />
                        <h3
                            className='medium line-height-5'
                            dangerouslySetInnerHTML={{__html: studio.name}} />
                        <div
                            className='wysiwyg'
                            dangerouslySetInnerHTML={{__html: studio.contact_info}} />
                    </div>
                ))}
            </section>
        );
    }
    if (props.page.acf.blocks && props.page.acf.blocks.length > 0) {
        blocks.push(
            <section
                key={'generic'}
                className='border-top border-white md-border-0 clearfix line-height-4 py4 sm-py6 md-pb7 md-pt0'>
                {props.page.acf.blocks.map((block, i) => (
                    <div
                        key={i}
                        className={`col col-12 md-col-6 h3 mb6 px4 sm-px6 md-px7 ${i % 2 !== 0 ? 'md-border-left border-white md-mln1px' : ''}`}>
                        {util.try(() => block.image) &&
                            <Image
                                mdWidth={45}
                                image={block.image} />
                        }
                        {util.try(() => block.title) &&
                            <h3
                                className='medium line-height-5'
                                dangerouslySetInnerHTML={{__html: block.title}} />
                        }
                        {util.try(() => block.body) &&
                            <div
                                className='wysiwyg'
                                dangerouslySetInnerHTML={{__html: block.body}} />
                        }
                    </div>
                ))}
            </section>
        );
    }

    return (

        <div className='bg-gray-01 container'>
            <Header menuItems={util.try(() => props.settings.menus.header)} />
            <section className='px4 sm-px6 md-px7 lg-pl0'>
                <h1
                    className='m0 py6'
                    dangerouslySetInnerHTML={{__html:props.title}} />
            </section>

            {blocks}

        </div>

    );
};

module.exports = Page;
