/*
 *  Home Padded Image module
 */

import React from 'react';
import util from '../../utilities';
import Image from '../images/image.jsx';
import ListingVideo from '../video/listing-video.jsx';

/**
 * Class representing a Padded Image
 * @extends React.Component
 * @prop {object} project
 */
class PaddedImage extends React.Component {

    render () {

        const project = this.props.project;

        let content;

        if (util.try(() => project.acf.homepage_listing_video_embed)) {
            content = (
                <ListingVideo
                    video={project.acf.homepage_listing_video_embed}
                    previewImage={util.try(() => project.acf.homepage_listing_preview_image.url)}
                    aspectWidth={util.try(() => project.acf.homepage_listing_video_aspect_ratio_width) || 16}
                    aspectHeight={util.try(() => project.acf.homepage_listing_video_aspect_ratio_height) || 9} />
            );
        } else {
            content = (
                <Image image={util.try(() => project.acf.homepage_listing_image) || util.try(() => project.acf.listing_image)} />
            );
        }

        return (
            <a
                className='link-reset hover-underline'
                href={'/work/' + project.slug}>
                <div className='flex flex-column col-12'>
                    <div
                        className='sm-p6 md-p7 lg-px0'>
                        {content}
                    </div>
                    <div className='px4 sm-px6 md-px7 lg-px0 py6 sm-pt0 sm-pb7'>
                        <h3 className='m0 medium mb1 inline-block relative'>
                            <span dangerouslySetInnerHTML={{__html: project.title}} />
                        </h3>
                        <h3 className='m0 light'>
                            {util.try(() => project.terms.type) ?
                                util.try(() => project.terms.type.map((type, i) => (
                                    <React.Fragment key={i}>
                                        {i > 0 &&
                                            <span>, </span>
                                        }
                                        <span>{type.name}</span>
                                    </React.Fragment>
                                )))
                                :
                                'nbsp;'
                            }
                        </h3>
                    </div>
                </div>
            </a>
        );
    }
}

module.exports = PaddedImage;
