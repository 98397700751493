import React from 'react';
import util from '../utilities';
import Header from '../partials/global/header.jsx';
import FullWidthImage from '../partials/home/full-width-image.jsx';
import PaddedImage from '../partials/home/padded-image.jsx';
import ImagePair from '../partials/home/image-pair.jsx';
import PaddedImagePair from '../partials/home/padded-image-pair.jsx';
import ArticleLink from '../partials/home/article-link.jsx';
import UpButton from '../partials/ui/up-button.jsx';

/*
 * Home page
 */

const PER_PAGE = 6;
const LOAD_DISTANCE = 600;

/**
 * Check scroll position
 * @param {number} threshold
 */
const isCloseToEnd = (threshold, element) => {
    const scrollPosition = window.pageYOffset;
    const viewportHeight = window.innerHeight;
    const pageHeight = document.body.offsetHeight;
    const distanceFromEnd = Math.max(element.getBoundingClientRect().bottom - (scrollPosition + viewportHeight), 0);
    return Boolean(distanceFromEnd < threshold);
};

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: util.try(() => this.props.page.acf.content.slice(0, PER_PAGE)),
            nextPage: util.try(() => this.props.page.acf.content.length > PER_PAGE),
            page: 1
        };
        this.checkScroll = this.checkScroll.bind(this);
        this.handlePage = this.handlePage.bind(this);
    }

    componentDidMount() {
        this.setState({
            pushStateSupport: !!(window.history && history.pushState)
        });
        if (this.state.nextPage) {
            this.interval = window.setInterval(this.checkScroll, 300);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            window.clearInterval(this.interval);
        }
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    }

    /**
     * Check distance to bottom of page and trigger page addition if required
     */
    checkScroll() {
        if (this.state.nextPage) {
            if (isCloseToEnd(LOAD_DISTANCE, this.refs.pageContainer)) {
                this.handlePage(this.state.page + 1);
            }
        }
    }

    /**
     * Add another page of items
     * @param {number} page
     */
    handlePage(page) {
        if (this.interval) {
            window.clearInterval(this.interval);
        }
        this.setState({
            items: this.props.page.acf.content.slice(0, (PER_PAGE * page) - 1),
            nextPage: this.props.page.acf.content.length > (PER_PAGE * page),
            page: page
        }, () => {
            this.timeout = window.setTimeout(() => {
                this.interval = window.setInterval(this.checkScroll, 300);
            }, 200);
        });
    }

    render() {

        const content = util.try(() => this.state.items.map((block, i) => {

            let component, project, projects, image;

            switch(block.acf_fc_layout) {

            case 'full_width_image':
                if (util.try(() => block.project[0])) {
                    component = (<FullWidthImage project={block.project[0]} />);
                } else {
                    component = '';
                }
                break;
            case 'padded_image':
                if (util.try(() => block.project[0])) {
                    component = (<PaddedImage project={block.project[0]} />);
                } else {
                    component = '';
                }
                break;
            case 'image_pair':
                if (util.try(() => block.projects[0]) && util.try(() => block.projects[1])) {
                    component = (<ImagePair projects={util.try(() => block.projects)} />);
                } else {
                    component = '';
                }
                break;
            case 'padded_image_pair':
                if (util.try(() => block.projects[0]) && util.try(() => block.projects[1])) {
                    component = (<PaddedImagePair projects={util.try(() => block.projects)} />);
                } else {
                    component = '';
                }
                break;
            case 'article_link':
                component = (<ArticleLink block={block} />);
                break;
            case 'linked_page':
                component = (<ArticleLink block={block} />);
                break;
            }

            const container = (block.acf_fc_layout !== 'full_width_image' && block.acf_fc_layout !== 'image_pair') ? 'container' : '';

            return (
                <div
                    key={i}
                    className={(block.acf_fc_layout === 'article_link' || block.acf_fc_layout === 'linked_page') ? 'bg-white' : ''}
                    style={{
                        borderBottom: i !== this.props.page.acf.content.length-1 ? '1px solid rgb(255, 255, 255)' : ''
                    }}>
                    <div className={`border-white ${container}`}>
                        {component}
                    </div>
                </div>
            );

        }));

        return (
            <div ref='pageContainer'>
                <Header menuItems={util.try(() => this.props.settings.menus.header)} />
                {content}
                <UpButton />
            </div>
        );
    }

};

module.exports = Home;
