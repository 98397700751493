import React from 'react';
import Header from '../partials/global/header.jsx';
import Image from '../partials/images/image.jsx';
import VideoPlayer from '../partials/video/video-player.jsx';
import TextSection from '../partials/shared/text-section.jsx';
import ListingComponent from '../partials/listing/projects/listing.jsx';
import ImagePair from '../partials/project/image-pair.jsx';
import VideoPair from '../partials/project/video-pair.jsx';
import util from '../utilities';

class ProjectDetail extends React.Component {
    render () {

        const buildContributor = (content, i) => (
            <div
                key={i || 'client'}
                className='border-top border-white line-height-3 clearfix py3'>
                <div
                    className='col col-6 sm-col-4 md-col-3'
                    dangerouslySetInnerHTML={{__html: content.role}} />
                <div className='col col-6 sm-col-8 md-col-9 pl4 sm-pl6 md-pl7'>
                    {content.name}
                </div>
            </div>
        );

        const clientContributor = buildContributor({
            role: 'Client',
            name: (
                <a
                    className='black link-reset border-bottom border-black'
                    href={'/work?client=' + util.try(() => this.props.post.client[0].slug)}
                    dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.client[0].name)}} />
            )
        });

        const url = util.try(() => encodeURIComponent(this.props.settings.urls.site) + this.props.settings.urls.path);
        const shareLinks = [
            {title: 'Facebook', link: `https://www.facebook.com/sharer/sharer.php?u=${url}`},
            {title: 'Twitter', link: `https://twitter.com/home?status=${url}`}
        ];

        return (
            <div className='container'>
                <Header menuItems={util.try(() => this.props.settings.menus.header)} />
                <div className='px4 sm-px6 md-px7 lg-px0 flex flex-wrap items-end justify-between md-pb3 col-12'>
                    <div className='col-12 md-col-8'>
                        <h1
                            className='m0 pt6 pb5 md-pb2 limit-line-length-1 sm-h5'
                            dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.title.rendered)}} />
                    </div>
                    <div className='col-12 sm-col-6 md-col-4 flex items-stretch justify-end'>
                        {this.props.post.type && this.props.post.type.length > 0 &&
                            <div className='mb5 col-6 md-border-left border-white md-pl7 pr3'>
                                <div className='h4 letter-spacing-1'>
                                    Type:
                                    <div className='pt1 line-height-1'>
                                        {this.props.post.type.map((type, i) => (
                                            <span key={i}>
                                                {type.name}
                                                {i < this.props.post.type.length-1 && ', '}
                                            </span>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        }
                        {this.props.post.acf.year &&
                            <div className={`mb5 col-6 border-left border-white ${!!this.props.post.type ? 'pl7 sm-pl6' : ''} md-pl7 pr3`}>
                                <div className='h4 letter-spacing-1'>
                                    Year:
                                    <div className='pt1 line-height-1'>
                                        <span dangerouslySetInnerHTML={{__html: this.props.post.acf.year}} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {util.try(() => this.props.post.acf.lead_image) &&
                    <Image image={this.props.post.acf.lead_image} />
                }
                {util.try(() => this.props.post.acf.lead_video_embed) &&
                    <VideoPlayer
                        previewImage={util.try(() => this.props.post.acf.lead_video_preview_image.url)}
                        aspectWidth={util.try(() => this.props.post.acf.lead_video_aspect_ratio_width) || 16}
                        aspectHeight={util.try(() => this.props.post.acf.lead_video_aspect_ratio_height) || 9}
                        video={this.props.post.acf.lead_video_embed} />
                }
                {util.try(() => this.props.post.acf.overview) &&
                    <TextSection
                        title='About'
                        content={this.props.post.acf.overview} />
                }
                {util.try(() => this.props.post.acf.contributors) && this.props.post.acf.contributors.length > 0 &&
                    <section className='clearfix px4 sm-px0 border-top sm-border-0 border-white py7 sm-pt0'>
                        <div className='col sm-col-4 sm-show pt1'></div>
                        <div className='col col-12 sm-col-8 h4 letter-spacing-1 sm-px6 md-px7 lg-pr0'>
                            {this.props.post.client && this.props.post.client.length > 0 &&
                                clientContributor
                            }
                            {this.props.post.acf.contributors.map((contributor, i) => (
                                buildContributor(contributor, i)
                            ))}
                        </div>
                    </section>
                }
                {util.try(() => this.props.post.tag) && this.props.post.tag.length > 0 &&
                    <section className='h4 clearfix px4 sm-px0 pb5 sm-pb6 sm-pt7 border-top border-white letter-spacing-1'>
                        <div className='col col-12 sm-col-4 sm-px6 md-px7 lg-pl0'>
                            <h3 className='m0 letter-spacing-1 light'>{`Subject${this.props.post.tag.length > 1 ? 's' : ''}:`}</h3>
                        </div>
                        <div className='col col-12 sm-col-8 sm-px6 md-px7 lg-pr0'>
                            <ul className='list-reset flex flex-wrap my0'>
                                {this.props.post.tag.map((tag, i) => (
                                    <li
                                        key={i}
                                        className='mr2 mb2'>
                                        <a
                                            href={'/work?subject=' + tag.slug}
                                            className='link-reset'>
                                            <div
                                                className='line-height-7 pt2px px3 bg-gray-02 bg-hover hover-yellow'
                                                dangerouslySetInnerHTML={{__html: tag.name}} />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </section>
                }
                {util.try(() => this.props.post.acf.gallery) && this.props.post.acf.gallery.length > 0 &&
                    <section className='clearfix flex flex-wrap border-white'>
                        {this.props.post.acf.gallery.map((item, i) => {

                            let border = '';

                            if (i % 2 === 0 && i !== this.props.post.acf.gallery.length-1) {
                                border = border + ' sm-border-right';
                            }
                            if (i <= 1) {
                                border = border + ' sm-border-top-0';
                            }
                            if (i === this.props.post.acf.gallery.length-2 && i % 2 !== 0) {
                                border = border + ' border-bottom mbn1px';
                            }
                            if (item.acf_fc_layout === 'video') {
                                return (
                                    <div
                                        key={i}
                                        className='col-12 p4 sm-p6 md-p7 border-white flex items-center border-top'>
                                        <div className='col-12'>
                                            <VideoPlayer
                                                previewImage={util.try(() => item.video_preview.url)}
                                                aspectWidth={util.try(() => item.video_aspect_ratio_width) || 16}
                                                aspectHeight={util.try(() => item.video_aspect_ratio_height) || 9}
                                                video={item.video} />
                                        </div>
                                    </div>
                                );
                            } else if (item.acf_fc_layout === 'video_pair') {
                                return (
                                    <VideoPair item={item} key={i} />
                                );
                            } else if (item.acf_fc_layout === 'image_pair') {
                                return (
                                    <ImagePair item={item} key={i} />
                                );
                            } else {
                                return (
                                    <div
                                        key={i}
                                        className='col-12 p4 sm-p6 md-p7 border-white flex items-center border-top'>
                                        <div className='col-12'>
                                            <Image image={item.image} />
                                        </div>
                                    </div>
                                );
                            }

                        })}
                    </section>
                }
                <section className='py7 sm-py7 md-py8 px4 sm-px6 md-px7 lg-px0 right-align'>
                    <h3 className='mt0 mb1 light'>Share</h3>
                    {
                        shareLinks.map((link, i) => (
                            <React.Fragment key={i}>
                                <a
                                    target='_blank'
                                    href={link.link}
                                    className='h3 link-reset'>
                                    <span>{link.title}</span>
                                </a>
                                {i < shareLinks.length-1 &&
                                    <span> — </span>
                                }
                            </React.Fragment>
                        ))
                    }
                </section>
                {((util.try(() => this.props.related) && this.props.related.length > 0) ||
                    (util.try(() => this.props.post.acf.related_projects) && this.props.post.acf.related_projects.length > 0)) &&
                    <section className='border-top border-white'>
                        <div className='flex justify-between items-end pt7 sm-pt7 md-pt8 px4 sm-px6 md-px7 lg-px0'>
                            {util.try(() => this.props.post.client[0]) &&
                                <React.Fragment>
                                    <h3 className='letter-spacing-0 m0 pr1'>
                                        Related Projects
                                    </h3>
                                    <a
                                        className='link-reset letter-spacing-1 nowrap h3'
                                        href={'/work?client=' + this.props.post.client[0].slug}>View all</a>
                                </React.Fragment>
                            }
                        </div>
                        <ListingComponent
                            divider={false}
                            pageSize={3}
                            items={util.try(() => this.props.related) || util.try(() => this.props.post.acf.related_projects)} />
                    </section>
                }
            </div>
        );
    }
}


module.exports = ProjectDetail;
