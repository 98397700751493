/*
 * Listing Video
 */

import React from 'react';
import BackgroundImage from '../images/background.jsx';

/**
 * Class representing listing video
 * @extends React.component
 * @prop {string} video
 */

const AUTOPLAY_THRESHOLD = 0.07;

class VideoPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    componentDidMount() {

        let scrolling = false;

        const videoHeight = this.refs.video.clientHeight;

        const checkInView = () => {
            const videoEleTop = this.refs.video.getBoundingClientRect().top;
            const videoEleBottom = this.refs.video.getBoundingClientRect().bottom;
            // if (videoEleTop - (window.innerHeight - window.innerHeight * AUTOPLAY_THRESHOLD) < 0 && videoEleBottom > (window.innerHeight * AUTOPLAY_THRESHOLD)) {
            if (videoEleTop - window.innerHeight < 0 && videoEleBottom > 0) {
                if (!this.state.active) {
                    this.setState({
                        active: true
                    }, () => {
                        const message = JSON.stringify({method: 'play'});
                        this.refs.video.firstChild.contentWindow.postMessage(message, this.props.video);
                    });
                }
            } else {
                if (this.state.active) {
                    this.setState({
                        active: false
                    }, () => {
                        const message = JSON.stringify({method: 'pause'});
                        this.refs.video.firstChild.contentWindow.postMessage(message, this.props.video);
                    });
                }
            }
        };

        this.refs.video.firstChild.addEventListener('load', checkInView);

        window.addEventListener('scroll', (e) => {
            if (!scrolling) {
                window.requestAnimationFrame(() => {
                    checkInView();
                    scrolling = false;
                });
                scrolling = true;
            }
        });

    }

    render() {

        return (
            <div
                ref='video'
                style={{
                    paddingBottom: parseFloat(this.props.aspectHeight) / parseFloat(this.props.aspectWidth) * 100 + '%'
                }}
                className='embed-container'>
                <iframe src={this.props.video + '?background=1&autoplay=0&autopause=0&muted=1&loop=1'} border='0' className='border-0' />
                {this.props.previewImage &&
                    <BackgroundImage className='zn1 absolute top-0 right-0 bottom-0 left-0' src={this.props.previewImage} />
                }
                <div className='absolute top-0 right-0 bottom-0 left-0 z1' />
            </div>
        );
    }

}


module.exports = VideoPlayer;
