import React from 'react';
import Container from '../base/container.jsx';
import Listing from './listing.jsx';
import Filter from '../filters/index.jsx';
import util from '../../../utilities';


class ClientListing extends Container {

    // TODO: render client list from state & build in filters.
    constructor (props) {
        super(props);

        this.toggleFilter = this.toggleFilter.bind(this);
        this.state.filtersActive = !!this.props.filters.order.current || false;
    }

    toggleFilter(filter) {
        this.setState({
            activeFilter: this.state.activeFilter === filter ? '' : filter
        });
    }

    render() {

        const activeClass = this.state.filtersActive ? 'show' : 'hide';

        // get name of current filter from filter name and id of 'current' filter
        const getCurrentFilter = (filter) => this.props.filters[filter].options
            .find(f => f.value === this.props.filters[filter].current)
            .label;

        const filters = Object.keys(this.props.filters).map((filter, i) => {
            let borderClass = '';
            if (i % 2 !== 0) {
                borderClass = 'pl7 sm-pl6 md-pl7 border-left border-white';
            } else {
                borderClass = 'md-pl7 md-border-left border-white';
            }
            return (
                <div
                    key={i}
                    className={`col col-6 ${borderClass}`}>
                    <button
                        className='button p0 capitalize light h4 left-align line-height-5 letter-spacing-1'
                        onClick={this.toggleFilter.bind(this, filter)}>
                        {filter}:
                        <div className='pt1 line-height-1'>
                            <span className={this.props.filters[filter].current && 'border-bottom border-black'}>
                                {this.props.filters[filter].current ? util.try(() => getCurrentFilter(filter)) : 'All ' + filter + 's'}
                            </span>
                        </div>
                    </button>
                </div>
            );
        });

        return (
            <div className=''>
                <div>
                    <div className='flex flex-wrap items-end col-12'>
                        <div className='col-12 md-col-10'>
                            <h1 className='px4 sm-px6 md-px7 lg-pl0 m0 py6 sm-pb5 md-pb2'>
                                Clients
                            </h1>
                        </div>
                        <div className='px4 sm-px6 md-pl0 md-pr7 lg-pr0 mb7 md-mb6 col-12 sm-col-6 md-col-2'>
                            <div className='relative'>
                                {filters}
                                <div
                                    style={{
                                        width: '1px',
                                        height: '100%',
                                        left: '50%'
                                    }}
                                    className='absolute bg-white pointer-events-none' />
                            </div>
                        </div>
                    </div>
                    <div className='px4 sm-px6 md-px7 lg-pr0'>
                        <Filter
                            defaultFilter={true}
                            active={this.state.activeFilter}
                            name='order'
                            handleFilter={this.handleFilter}
                            filter={this.props.filters.order} />
                    </div>
                </div>

                <Listing
                    path='/clients-json'
                    query={{
                        order: util.try(() => this.state.filters.order.current)
                    }}
                    items={this.props.items}
                    onLoad={this.handleLoad} />
            </div>
        );
    }
}

module.exports = ClientListing;
