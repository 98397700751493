/*
 * AJAX listing
 */

import React from 'react';
import AjaxListing from './ajax-listing.jsx';


/**
 * Class representing an AJAX listing
 * @extends React.component
 * @prop {string} path
 * @prop {object} query
 * @prop {array} items
 * @prop {function} onLoad
 */
class InfiniteAjaxListing extends AjaxListing  {

    /**
     * Setup
     */
    constructor(props) {
        super(props);
        this.handleData = this.handleData.bind(this);
    }


    /**
     * Placeholder method to handle response
     * @param {object} data
     */
    handleData(data, callback) {
        let items = this.state.items || [];
        if (data.posts) {
            items = this.props.query.pages === 1
                ? data.posts
                : items.concat(data.posts);
        }
        this.setState({
            items: items
        }, () => {
            callback();
        });
    }

}

module.exports = InfiniteAjaxListing;
