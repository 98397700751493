/*
 *  Project Detail Video Pair module
 */

import React from 'react';
import VideoPlayer from '../video/video-player.jsx';
import util from '../../utilities';

/**
 * Class representing an Video Pair
 * @extends React.Component
 * @prop {object} item
 */
const VideoPair = (props) => {
    return (
        <React.Fragment>
            <div className={`col-12 sm-col-6 p4 sm-p6 md-p7 border-white flex items-center border-top ${props.item.video_2 ? 'sm-border-right' : ''}`}>
                <VideoPlayer
                    previewImage={util.try(() => props.item.video_preview_1.sizes['custom-medium'])}
                    aspectWidth={util.try(() => props.item.video_1_aspect_ratio_width) || 16}
                    aspectHeight={util.try(() => props.item.video_1_aspect_ratio_height) || 9}
                    video={props.item.video_1} />
            </div>
            <div className={`col-12 sm-col-6 ${props.item.video_2 ? 'p4 sm-p6 md-p7 border-top' : ''} border-white flex items-center sm-border-top`}>
                {util.try(() => props.item.video_2) &&
                    <VideoPlayer
                        previewImage={util.try(() => props.item.video_preview_2.sizes['custom-medium'])}
                        aspectWidth={util.try(() => props.item.video_2_aspect_ratio_width) || 16}
                        aspectHeight={util.try(() => props.item.video_2_aspect_ratio_height) || 9}
                        video={props.item.video_2} />
                }
            </div>
        </React.Fragment>
    );
};

module.exports = VideoPair;
