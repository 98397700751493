/**
*   Grid item for listing
*/
import React from 'react';
import util from '../../../utilities';
import Image from '../../images/image.jsx';
import LoadedImage from '../../images/loaded-image.jsx';

const ImageItem = (props) => {

    const item = props.item;
    const baseUrl = props.baseUrl;
    const title = util.try(() => item.title.rendered ? item.title.rendered : item.title);

    let url = '';
    if (util.try(() => props.baseUrl)) {
        url = props.baseUrl + '/' + item.slug;
    } else {
        url = util.joinPath(item.type, item.slug);
    }

    return (
        <div className={`col col-12 sm-col-${props.width}`}>
            <a
                className='block link-reset height-100 pt4 sm-pt6 md-pt7'
                href={url}>
                <div className='px4 sm-px6 md-px7 pb4 sm-pb6 md-pb7 height-100'>
                    {util.try(() => props.typeTitle) &&
                        <div className='px5 pt2 pt2'>
                            <span
                                className='h7 caps'
                                dangerouslySetInnerHTML={{__html:props.typeTitle}} />
                        </div>
                    }
                    <div className=''>
                        <LoadedImage
                            width={100}
                            smWidth={35}
                            mdWidth={25}
                            image={util.try(() => item.acf.listing_image) || util.try(() => item.acf.lead_image)} />
                    </div>
                </div>
            </a>
        </div>
    );

};

export default ImageItem;
