/*
 *  Background images
 */

import React from 'react';


/**
 * Class representing a background image
 * @extends React.Component
 * @prop {string} src
 * @prop {string} className
 */
class BackgroundImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }

    // update state when the image is loaded
    componentDidMount() {
        this.imageNode = new Image();
        this.imageNode.src = this.props.src;
        if (this.imageNode.complete) {
            this.handleOnLoad.call(this);
        } else {
            this.imageNode.onload = this.handleOnLoad.bind(this);
        }
    }

    // remove the loaded listener if the component will unmount
    componentWillUnmount() {
        if (this.state.loaded === false) {
            this.imageNode.onload = undefined;
        }
    }

    // handle load
    handleOnLoad() {
        this.setState({
            loaded: true
        });
    }

    // fade in
    componentDidUpdate(prevProps, prevState) {
        if (this.state.loaded && !prevState.loaded) {
            window.requestAnimationFrame(() => {
                if (this.refs.image) {
                    this.refs.image.style.opacity = 1;
                }
            });
        }
    }

    // render image
    render() {

        const src = this.props.src || '';
        let classes = this.props.className || '';
        classes += ' transition-opacity';

        const opacity = this.props.static ? 1 : 0.01;

        let style = {
            opacity: opacity,
            backgroundSize: 'cover',
            backgroundRepeat: 'none',
            backgroundPosition: 'center',
            backgroundImage: `url(${src})`
        };

        return (
            <div
                ref='image'
                className={classes}
                style={style}>
                {this.props.children}
            </div>
        );

    }

};

module.exports = BackgroundImage;
