/**
*   Grid item for listing
*/
import React from 'react';
import util from '../../../utilities';
import Image from '../../images/image.jsx';
import LoadedImage from '../../images/loaded-image.jsx';

class TextItem extends React.Component {

    render () {

        const item = this.props.item;
        const baseUrl = this.props.baseUrl;
        const title = util.try(() => item.title.rendered ? item.title.rendered : item.title);

        let url;
        if (util.try(() => this.props.baseUrl)) {
            url = this.props.baseUrl + '/' + item.slug;
        } else {
            url = util.joinPath(item.type, item.slug);
        }

        return (
            <div className={`col col-12 sm-col-${this.props.width}`}>
                <a className='block link-reset height-100' href={url}>
                    <div className='px4 pb4 sm-px6 sm-pb6 md-px7 md-pb7 height-100'>
                        {util.try(() => this.props.typeTitle) &&
                            <div className='px5 pt2 pt2'>
                                <span
                                    className='h7 caps'
                                    dangerouslySetInnerHTML={{__html:this.props.typeTitle}} />
                            </div>
                        }
                        <div className='line-height-4'>
                            <h3
                                dangerouslySetInnerHTML={{__html: title}}
                                className='m0 medium letter-spacing-0' />
                            {item.type && item.type.length > 0 &&
                                <h3 className='m0 light'>
                                    {item.type && item.type.length > 0 ?
                                        util.try(() => item.type.map((type, i) => (
                                            <React.Fragment key={i}>
                                                {i > 0 &&
                                                    <span>, </span>
                                                }
                                                <span>{type.name}</span>
                                            </React.Fragment>
                                        )))
                                        :
                                        'nbsp;'
                                    }
                                </h3>
                            }
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

export default TextItem;
