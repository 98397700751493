/*
 * Error page
 */

import React from 'react';
import util from '../utilities';
import Logo from '../partials/ui/logo.jsx';


/**
 * Class representing the error pages
 * @extends React.component
 * @prop {object} page
 * @prop {object} settings
 */
class ErrorPage extends React.Component {

    render() {

        const message = this.props.err.message || 'Page not found.';

        return (
            <div className="container">

                <header>
                    <a
                        href='/'
                        className='py6 pl4 sm-pl6 md-pl7 lg-pl0 inline-block overflow-hidden'>
                        <Logo fill={'black'} />
                    </a>
                </header>

                <div className='pt4 sm-pt5 pb4 sm-pb5 px3 sm-px5 center charcoal-001'>
                    <h3 className='bold m0 mb2' dangerouslySetInnerHTML={{__html:this.props.err.status}} />
                    <p dangerouslySetInnerHTML={{__html:message}} className='letter-spacing-3'/>
                </div>
                <div className='mr3 ml3 mt7 mb7 center'>
                    <a href='/' className='border-bottom letter-spacing-3 link-reset'>Back to Home</a>
                </div>
            </div>
        );

    }

};

module.exports = ErrorPage;
