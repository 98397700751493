/*
 *  Home Article Link module
 */

import React from 'react';
import util from '../../utilities';


/**
 * Class representing an Article Link
 * @extends React.Component
 * @prop {object} block
 */
class ArticleLink extends React.Component {

    render() {

        let url;
        const block = this.props.block;

        if (block.acf_fc_layout === 'linked_page') {
            url = util.try(() => block.page);
        } else if (block.acf_fc_layout === 'article_link') {
            if (block.internal_external === 'external') {
                url = util.try(() => block.external_link);
            } else {
                if (util.try(() => block.internal_project[0].slug)) {
                    url = '/work/' + block.internal_project[0].slug;
                }
                if (util.try(() => block.internal_page[0].slug)) {
                    url = '/work/' + block.internal_page[0].slug;
                }
            }
        }


        return (
            <div>
                {(block.category || block.date) &&
                    <div className='flex justify-between'>
                        <h3
                            className='medium sm-col-6 px4 sm-px6 md-px7 lg-pl0 mb0 mt4 sm-mt6 md-mt7'
                            dangerouslySetInnerHTML={{__html: util.try(() => block.category)}} />
                        <h3
                            className='medium gray-03 sm-col-6 px4 sm-px6 md-px7 mb0 mt4 sm-mt6 md-mt7'
                            dangerouslySetInnerHTML={{__html: util.try(() => block.date)}} />
                    </div>
                }
                <div className='px4 sm-px6 md-px7 lg-pl0 sm-pt6 sm-pb7 md-pt7 md-pb8 clearfix'>
                    <a
                        href={url}
                        target={block.internal_external === 'external' ? '_blank' : ''}
                        className={`link-reset hover-underline ${block.internal_external === 'external' ? 'hover-external' : ''}`}>
                        <h1
                            className='limit-line-length-3 mt8 mb8 break-word sm-h5'
                            dangerouslySetInnerHTML={{__html: util.try(() => block.title)}} />
                    </a>
                </div>
            </div>
        );

    }
}


module.exports = ArticleLink;
