/*
 *  Lightbox module
 */

import React from 'react';
import util from '../../utilities';
import LoadedImage from '../images/loaded-image.jsx';

/**
 * Class representing an Image Pair
 * @extends React.Component
 * @prop {object} image
 */

class Lightbox extends React.Component {

    componentDidMount() {
        document.body.classList.toggle('overflow-hidden', true);
    }

    componentWillUnmount() {
        document.body.classList.toggle('overflow-hidden', false);
    }

    render() {
        return (
            <div
                onClick={() => this.props.close(null)}
                className='fixed top-0 right-0 bottom-0 left-0 bg-gray-01 z1 overflow-y-scroll md-cursor-cross'>
                <div className='container min-height-100 pt10 pb10'>
                    <div className='md-hide relative'>
                        <div
                            onClick={() => this.props.close(null)}
                            className='py6 pl6 pr4 z2 cursor-pointer right burger-icon js-is-active bottom-0'>
                            <span className='bg-black transition line' />
                            <span className='bg-black transition line' />
                            <span className='bg-black transition line' />
                        </div>
                    </div>
                    <LoadedImage
                        style={{
                            transform: 'translate(-50%, -50%)',
                            top: '50%',
                            left: '50%'
                        }}
                        image={this.props.image} />
                    <div className='center mt4 md-hide'>
                        <span className='border-bottom letter-spacing-3'>
                            Close
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = Lightbox;
