import React from 'react';
import AjaxListing from '../base/ajax-listing.jsx';
import Filter from '../filters/index.jsx';


class Listing extends AjaxListing {

    // TODO: render client list from state & build in filters.
    render() {


        // group clients with first letter as key
        let clients = {};
        this.state.items.forEach((client, i) => {
            const key = client.name[0].toLowerCase();
            if (!clients[key]) {
                clients[key] = [];
            }
            clients[key].push(client);
        });

        // render function for each letter group
        const renderClient = (key) => (
            <div
                key={key}
                className='py7 clearfix'>
                <div
                    style={{marginTop: '-0.1em'}}
                    className='pl4 sm-pl6 md-pl7 lg-pl0 h1 col col-4 md-show caps bold'>
                    {key}
                </div>
                <div className='col col-12 md-col-8 m0 md-border-left border-white'>
                    <ul
                        className='list-reset'
                        style={{marginTop: '-0.7em', marginBottom: '-1.1em'}}>
                        {clients[key].map((client, i) => (
                            <li
                                key={i}
                                className='px4 sm-px6 md-px7'>
                                <a
                                    className='h1 bold link-reset'
                                    href={`/work?client=${client.slug}`}
                                    dangerouslySetInnerHTML={{__html: client.name}} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );

        return (
            // render function for each letter group
            <div className='border-top md-border-0 border-white pt6 sm-pt7'>
                {Object.keys(clients).map(renderClient)}
            </div>
        );
    }
}

module.exports = Listing;
