import React from 'react';
import Listing from '../partials/listing/clients/index.jsx';
import Header from '../partials/global/header.jsx';
import util from '../utilities';


class ClientListing extends React.Component {

    render() {
        return (
            <div className='container'>
                <Header
                    menuItems={util.try(() => this.props.settings.menus.header)}/>
                <Listing
                    filters={this.props.filters}
                    items={this.props.posts} />
            </div>
        );
    }
}

module.exports = ClientListing;
