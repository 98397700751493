
import React from 'react';
import util from '../utilities';
import ListingComponent from '../partials/listing/projects/index.jsx';
import Header from '../partials/global/header.jsx';


class Projects extends React.Component {

    render() {

        return (
            <section className='bg-gray-01 container'>
                <Header
                    menuItems={util.try(() => this.props.settings.menus.header)} />
                <ListingComponent
                    client={util.try(() => this.props.client)}
                    cols={3}
                    divider={true}
                    page={this.props.page}
                    items={util.try(() => this.props.posts.posts)}
                    total={util.try(() => this.props.posts.total)}
                    totalPages={util.try(() => this.props.posts.totalPages)}
                    morePages={util.try(() => this.props.posts.totalPages > this.props.posts.currentPage)}
                    currentPage={util.try(() => this.props.posts.currentPage)}
                    nextPage={util.try(() => this.props.posts.nextPage)}
                    filters={this.props.filters} />
            </section>
        );
    }
}

module.exports = Projects;
