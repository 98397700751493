/*
 * Listing Container
 */

import React from 'react';
import util from '../../../utilities';
import InfiniteContainer from '../base/infinite-container.jsx';
import Listing from './listing.jsx';
import Pagination from '../shared/pagination.jsx';
import Filter from '../filters/index.jsx';

/**
 * Class representing the Listing Container
 * @extends React.Component
 */

class ListingContainer extends InfiniteContainer {

    constructor (props) {
        super(props);

        this.handleLoad = this.handleLoad.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.state.totalPages = this.props.totalPages || 1;
        this.state.total = this.props.total || 0;
        this.state.filtersActive = !!this.props.filters.type.current || false;
        this.state.featuredItems = util.try(() => this.props.content.acf.featured);
    }

    componentWillMount() {
        if (util.try(() => this.props.filters)) {
            Object.keys(this.props.filters).forEach((filter, i) => {
                if (this.props.filters[filter].current) {
                    this.toggleFilter(filter);
                }
            });
        }
    }

    handleSelect(value) {
        if (typeof this.handleFilter === 'function') {
            if(value === 'all') {
                value = '';
            }
            this.handleFilter('type', value);
        }
    }

    toggleFilter(filter) {
        this.setState({
            activeFilter: this.state.activeFilter === filter ? '' : filter
        });
    }

    render () {

        const activeClass = this.state.filtersActive ? 'show' : 'hide';

        // get name of current filter from filter name and id of 'current' filter
        const getCurrentFilter = (filter) => this.props.filters[filter].options
            .find(f => f.value === this.props.filters[filter].current)
            .label;

        const filters = Object.keys(this.props.filters).map((filter, i) => {
            let borderClass = '';
            if (i % 2 !== 0) {
                borderClass = 'pl7 sm-pl6 md-pl7 border-left border-white';
            } else {
                borderClass = 'md-pl7 md-border-left border-white';
            }
            return (
                <div
                    key={i}
                    className={`col col-6 ${borderClass}`}>
                    <button
                        className='button p0 capitalize light h4 left-align line-height-5 letter-spacing-1'
                        onClick={this.toggleFilter.bind(this, filter)}>
                        {filter}:
                        <div className='pt1 line-height-1'>
                            <span className={this.props.filters[filter].current && 'border-bottom border-black'}>
                                {this.props.filters[filter].current ? util.try(() => getCurrentFilter(filter)) : 'All ' + filter + 's'}
                            </span>
                        </div>
                    </button>
                </div>
            );
        });

        return (
            <div>
                <div className='clearfix lg-mxn7'>
                    <div className='flex flex-wrap items-end col-12'>
                        <div className='col-12 md-col-8'>
                            <h1 className='px4 sm-px6 md-px7 lg-pl7 m0 py6 sm-pb5 md-pb2'>
                                {util.try(() => this.props.client.name) || 'Selected Works'}
                            </h1>
                        </div>
                        <div className='px4 sm-px6 md-pl0 md-pr7 lg-pr7 mb2 sm-mb5 col-12 sm-col-6 md-col-4'>
                            <div className='relative'>
                                {filters}
                                <div
                                    style={{
                                        width: '1px',
                                        height: '100%',
                                        left: '50%'
                                    }}
                                    className='absolute bg-white pointer-events-none' />
                            </div>
                        </div>
                    </div>
                    <div className='px4 sm-px6 md-px7 lg-px7'>
                        <Filter
                            active={this.state.activeFilter}
                            name='type'
                            handleFilter={this.handleFilter}
                            filter={this.props.filters.type} />
                        <Filter
                            active={this.state.activeFilter}
                            name='subject'
                            handleFilter={this.handleFilter}
                            filter={this.props.filters.subject} />
                    </div>
                </div>

                {/* Listing */}
                <Listing
                    path='/work-json'
                    divider={this.props.divider}
                    query={{
                        pages: this.state.pages,
                        type: util.try(() => this.state.filters.type.current),
                        subject: util.try(() => this.state.filters.subject.current),
                        client: util.try(() => this.props.client.slug)
                    }}
                    items={this.props.items}
                    pageSize={this.props.cols || 3}
                    morePages={this.props.morePages}
                    onLoad={this.handleLoad}
                    page={this.state.page}
                    total={this.state.total} />

                {(this.state.morePages && !this.state.loading) && (
                    <div
                        className='border-bottom border-white py3 px4 sm-px6 md-px7 lg-pl0 h1 bold cursor-pointer'
                        onClick={this.loadMorePosts}>
                        +
                    </div>
                )}
                {this.state.loading && (
                    <div
                        className='border-bottom border-white py3 px4 sm-px6 md-px7 lg-pl0 h1 bold'>
                        Loading...
                    </div>
                )}
            </div>
        );
    }
}

module.exports = ListingContainer;
