
/*
 * Listing container
 */

import React from 'react';
import util from '../../../utilities';


/**
 * Class representing a listing container
 * @extends React.Component
 * @prop {object} filters
 * @prop {array} items
 * @prop {number} page
 */
class ListingContainer extends React.Component {

    /**
     * Setup
     */
    constructor(props) {
        super(props);

        this.handleFilter = this.handleFilter.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            filters: props.filters || {},
            items: props.items || [],
            page: !isNaN(props.page) ? props.page : this.props.currentPage ||  1
        };
    }

    /**
     * Setup
     */
    componentDidMount() {
        this.setState({
            pushStateSupport: !!(window.history && history.pushState)
        });
    }

    /**
     * Handle filter
     * @param {string} key
     * @param {any} value
     */
    handleFilter(key, value) {

        const filters = this.state.filters;
        if (filters[key]) {
            filters[key].current = (filters[key].current === value) ? null : value;
        }

        this.setState({
            filters: filters,
            pages: 1,
        }, () => {
            if (this.state.pushStateSupport) {
                util.updateHistoryState('pages', 1);
                util.updateHistoryState(key,
                    (this.state.filters[key].current) ? value : null
                );
            }
        });
    }

    /**
     * Navigate to a new page
     * @param {number} page
     * @param {function} callback
     */
    handlePage(pages, callback) {
        this.setState({
            pages: pages
        }, () => {
            if (this.state.pushStateSupport) {
                util.updateHistoryState('pages', pages);
            }
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

}

module.exports = ListingContainer;
