/*
* Vimeo Embed Video Player
*/

import React from 'react';
import BackgroundImage from '../images/background.jsx';

/**
* Class representing video player
* @extends React.component
* @prop {string} video
*/

class VideoPlayer extends React.Component {

    render() {
        return (
            <div
                ref='video'
                style={{
                    paddingBottom: parseFloat(this.props.aspectHeight) / parseFloat(this.props.aspectWidth) * 100 + '%'
                }}
                className='embed-container col-12'>
                <iframe
                    className='border-0'
                    src={this.props.video + '?loop=1'} />
                {this.props.previewImage &&
                    <BackgroundImage className='zn1 absolute top-0 right-0 bottom-0 left-0' src={this.props.previewImage} />
                }
            </div>
        );
    }
}


module.exports = VideoPlayer;
